import { MainLayout } from "@/layouts";
import routes from "./routes";

let ReportsModule =  {
    path: "/reporting",
    component: MainLayout,
    meta: {
        label: "Reporting",
        include: true
    },
    children: [...routes]
};

export default ReportsModule;