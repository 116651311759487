<template>
    <div class="content-wrapper report-tree">
        <rq-treeview-layout
            :flyout-enabled="isItemSelected">
            <template #tree>
                <reports-tree-view
                    @item-selected="onItemSelected"
                    @report-tree-refresh="onReportTreeRefresh"
                />
            </template>
            <report-executor
                :key="reportExecutorKey"
                :report-options="reportOptions"
            />
        </rq-treeview-layout>
    </div>
</template>
<script>
    import ReportsTreeView from "../components/ReportsTreeView";
    import ReportExecutor from "@/shared/components/exago/ReportExecutor";
    import LookupNames from "@/store/lookupNames";
    import { REFRESH_LOOKUP } from "@/store/actions";

    export default {
        name: "ReportTree",
        components: {
            ReportsTreeView,
            ReportExecutor
        },
        data () {
            return {
                reportExecutorKey: 0,
                reportOptions: {}
            };
        },
        computed: {
            isItemSelected() { return !_.isEmpty(this.reportOptions); }
        },
        created() {
            this.$store.dispatch(REFRESH_LOOKUP, { lookupKeys: LookupNames.STAFF });
        },
        methods: {
            onItemSelected(e) {
                const self = this;
                if(e.selectedItem.isFolder) {
                    self.reportOptions = {};
                    return;
                }
                self.reportOptions = _.defaults(_.pick(e.selectedItem, ["path","reportID","documentTemplateID","name","ext","physicalPath","isCustomReport"]),{
                    path: null,
                    reportID: null,
                    documentTemplateID: null,
                    name: null,
                    ext: null,
                    physicalPath: null,
                    isCustomReport: null
                });
            },
            onReportTreeRefresh(e) {
                const self = this;
                self.reportExecutorKey += 1;
            }
        }
    }
</script>
