<template>
    <rq-tree-view
        ref="treeViewComponent"
        id="rq-reports-tree-view"
        title="Reports"
        :key="treeViewComponentKey"
        :data-source="treeDataSource"
        :disabled="disabled"
        v-model:expanded-keys="expandedKeys"
        :default-expanded-keys="defaultExpandedKeys"
        @item-selected="$emit('item-selected', $event)"
        expand-selected
        persist-expanded
        show-title-border>
        <template #item-label="{ item }">
            <FontAwesomeIcon v-if="item.isFolder && item.isExpanded" icon="fas fa-folder-open" />
            <FontAwesomeIcon v-else-if="item.isFolder" icon="fas fa-folder" />
            <FontAwesomeIcon v-else icon="far fa-file-alt" />
            <span :title="item.displayName" class="ms-1">
                {{item.displayName}}
            </span>
        </template>
    </rq-tree-view>
</template>

<script>
    import { EXAGO_SESSION_ACTIONS } from "@/store/actions";
    import { DocumentReportNodeDto } from "../models.js";
    import { BiReportTreeItem } from "@config/documentsReports/models.js";

    export default {
        name: "ReportsTreeView",

        props: {
            title: { type: String, default: null },
            disabled: { type: Boolean, default: false }
        },

        data() {
            return {
                treeViewComponentKey: 0,
                expandedKeys: null,
                defaultExpandedKeys: null
            }
        },

        created() {
            const self = this;
            self.fetchReportsAccess();
            self.treeDataSource = { load: self.load };
        },

        methods: {
            load(parent) {
                const self = this;

                if (!_.isNil(parent)) return Promise.resolve(parent.children);

                let apiPromise = Promise.all([
                    self.$api.ReportsApi.getReportTree(),
                    self.$api.DocumentReportsApi.getTree()
                ]);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let biReportItems = _.map(result[0], item => new BiReportTreeItem(item));
                        self.setDefaultExpandedKeys(biReportItems);
                        let docReportItems = _.map(result[1], item => new DocumentReportNodeDto(item));
                        return _.concat(biReportItems, docReportItems);
                    });
            },
            setDefaultExpandedKeys(items) {
                let systemReportsItem = _.find(items, item => item.name === "System Reports");
                if(_.isNil(systemReportsItem)) return;
                this.defaultExpandedKeys = [systemReportsItem.key];
            },
            refreshTree() {
                const self = this;
                self.treeViewComponentKey += 1;
            },
            refreshParent() {
                const self = this;
                this.$emit('report-tree-refresh', 1);
            },
            fetchReportsAccess() {
                const self = this;
                self.$store.dispatch(EXAGO_SESSION_ACTIONS.GET_REPORT_API_URL);
            },
        }
    };
</script>