import { BaseEnumeration } from '@/shared/models/models';
import { DateTime } from "luxon";

export const ReportIframeMode = {
    STAND_ALONE: 1,
    REPORT_VIEWER: 2,
}

export const DATA_TYPE = {
    DATE: 1,
    MONEY: 2,
    TEXT: 3,
    NUMBER: 4,
    SINGLE_SELECT: 5,
    MULTI_SELECT: 6,
    ORDER: 7,
    COMPANY: 8,
    BOOLEAN: 9,
    RADIO: 10
}

export class BiReportDto {
    constructor(options) {
        options = options || {};
        this.reportID = options.reportID || "";
        this.label = options.label || "";
        this.path = options.path || "";
        this.requiredCount = _.parseNumber(options.requiredCount, 0);
        this.lastExecuted = options.lastExecuted || null;

        let paramValues = _.isArray(options.parameters) ? options.parameters : [];
        this.parameters = _.map(paramValues, p => new BiParameterReportDto(p));
    }
    toDataObject() {
        let result = _.toPlainObject(this);
        result.parameters = _.map(this.parameters, _.toPlainObject);
        return result;
    }
}

export class BiParameterReport {
    constructor(options) {
        options = options || {};
        this.biParameterReportID = _.parseNumber(options.biParameterReportID, 0);
        this.reportID = options.reportID || "";
        this.biParameterID = _.parseNumber(options.biParameterID, 0);
        this.label = options.label || "";
        this.displayOrder = _.parseNumber(options.displayOrder, 0);
        this.required = _.parseBool(options.required, false);
        this.showInactive = _.parseBool(options.showInactive, false);
        this.defaultValue = _.isNil(options.defaultValue) ? null : options.defaultValue;
        this.availableSelectionsCount = _.parseNumber(options.availableSelectionsCount, 0);
    }
}

export class BiParameterReportDto extends BiParameterReport {
    constructor(options) {
        super(options);
        options = options || {};
        this.name = options.name || "";
        this.dataType = options.dataType || "";
        this.lookupKey = options.lookupKey || "";
        this.defaultValue = this.parseDefaultValue(options.defaultValue);
    }

    parseDefaultValue(valueString) {
        switch(this.dataType) {
            case DATA_TYPE.DATE:
            case DATA_TYPE.TEXT:
            case DATA_TYPE.RADIO:
            case DATA_TYPE.COMPANY: return valueString;

            case DATA_TYPE.MONEY:
            case DATA_TYPE.NUMBER:
            case DATA_TYPE.SINGLE_SELECT:
            case DATA_TYPE.ORDER: return _.parseNumber(valueString, null);

            case DATA_TYPE.MULTI_SELECT: return _.isNil(valueString) ? null : _.split(valueString, ",");

            case DATA_TYPE.BOOLEAN: return _.parseBool(valueString);
        }
    }
}
export class ReportOptionsDto {
    constructor(options) {
        options = options || {};
        this.title = options.title || '';
        this.reportPath = options.reportPath || '';
        this.parameters = options.parameters || {};
        this.immediate = _.parseBool(options.immediate, true);
        this.disabled = _.parseBool(options.disabled, false);
        this.preExecuteHook = _.isFunction(options.preExecuteHook) ? options.preExecuteHook : null;
    }
}

const QUICK_DATE_TYPE = {
    LAST_YEAR:          { id: 1   , name: 'Last Year'           , offset: { years:   -1 }     , period: 'year'        },
    YEAR_TO_DATE:       { id: 2   , name: 'Year To Date'        , offset: { years:    0 }     , period: 'year'        },
    CUSTOM_DATE:        { id: 3   , name: 'Custom Date'         , offset: { days:     0 }     , period: 'day'         },
};

const SPEED_DATE_TYPE = {
    NEXT_WEEK:          { id: 1   , name: 'Next Week'           , offset: { weeks:    1 }     , period: 'week'        },
    NEXT_MONTH:         { id: 2   , name: 'Next Month'          , offset: { months:   1 }     , period: 'month'       },
    NEXT_QUARTER:       { id: 3   , name: 'Next Quarter'        , offset: { quarters: 1 }     , period: 'quarter'     },
    NEXT_YEAR:          { id: 4   , name: 'Next Year'           , offset: { years:    1 }     , period: 'year'        },
    NEXT_7_DAYS:        { id: 5   , name: 'Next 7 Days'         , offset: { days:     6 }     , period: 'day'         },
    TOMORROW:           { id: 6   , name: 'Tomorrow'            , offset: { days:     1 }     , period: 'day'         },
    TODAY:              { id: 7   , name: 'Today'               , offset: { days:     0 }     , period: 'day'         },
    YESTERDAY:          { id: 8   , name: 'Yesterday'           , offset: { days:    -1 }     , period: 'day'         },
    THIS_WEEK:          { id: 9   , name: 'This Week'           , offset: { weeks:    0 }     , period: 'week'        },
    MONTH_TO_YESTERDAY: { id: 10  , name: 'Month to Yesterday'  , offset: { months:   0 }     , period: 'month'       },
    THIS_MONTH:         { id: 11  , name: 'This Month'          , offset: { months:   0 }     , period: 'month'       },
    THIS_QUARTER:       { id: 12  , name: 'This Quarter'        , offset: { quarters: 0 }     , period: 'quarter'     },
    THIS_YEAR:          { id: 13  , name: 'This Year'           , offset: { years:    0 }     , period: 'year'        },
    LAST_WEEK:          { id: 14  , name: 'Last Week'           , offset: { weeks:   -1 }     , period: 'week'        },
    LAST_MONTH:         { id: 15  , name: 'Last Month'          , offset: { months:  -1 }     , period: 'month'       },
    LAST_QUARTER:       { id: 16  , name: 'Last Quarter'        , offset: { quarters:-1 }     , period: 'quarter'     },
    LAST_YEAR:          { id: 17  , name: 'Last Year'           , offset: { years:   -1 }     , period: 'year'        },
};

class QuickDateOptions extends BaseEnumeration {
    constructor() { super(QUICK_DATE_TYPE, 'id', 'name', 'id'); }
}

class SpeedDateOptions extends BaseEnumeration {
    constructor() { super(SPEED_DATE_TYPE, 'id', 'name', 'id'); }
}


export const QUICK_DATE = Object.freeze(new QuickDateOptions());

export const SPEED_DATE = Object.freeze(new SpeedDateOptions());

const EXAGO_PARAMETERS = {
    ACCOUNT_CODES:      { id: 'p_AccountCodes'      },
    BANKS:              { id: 'p_Banks'             },
    BRANCHES:           { id: 'p_Branches'          },
    COMPANIES:          { id: 'p_CompanyIDs'        },
    CONTACTS:           { id: 'p_ContactIDs'        },
    DEPARTMENTS:        { id: 'p_Departments'       },
    DEPOSIT:            { id: 'p_DepositID'         },
    DEPOSITS:           { id: 'p_DepositIDs'        },
    DEPOSIT_STATUS:     { id: 'p_DepositStatus'     },
    END_DATE:           { id: 'p_EndDate'           },
    IS_CDF:             { id: 'p_IsCdf'             },
    LOOKUP_TYPES:       { id: 'p_LookupTypes'       },
    OPEN_CLOSED:        { id: 'p_OpenClosed'        },
    OPEN_CLOSED_STATUS: { id: 'p_OpenClosedStatus'  },
    ORDER_STATUS:       { id: 'p_OrderStatus'       },
    ORDER:              { id: 'p_OrdersID'          },
    ORDERS:             { id: 'p_OrderIDs'          },
    QUICK_DATE:         { id: 'QuickDate'          },
    REGIONS:            { id: 'p_Regions'           },
    REPORT_TYPES:       { id: 'p_ReportTypes'       },
    ROLE_TYPES:         { id: 'p_RoleTypes'         },
    SEARCH_BY:          { id: 'p_SearchBy'          },
    SPEED_DATE:         { id: 'SpeedDate'           },
    STAFF_TYPES:        { id: 'p_StaffTypes'        },
    STAFF_TYPES_1099:   { id: 'p_StaffTypes1099'    },
    STAFFS:             { id: 'p_Staffs'            },
    START_DATE:         { id: 'p_StartDate'         },
    TASKS:              { id: 'p_Tasks'             },
    TITLE_COMPANIES:    { id: 'p_TitleCompanies'    },
    USERS:              { id: 'p_Users'             },
};

class ExagoParameters extends BaseEnumeration {
    constructor() { super(EXAGO_PARAMETERS, 'id', ''); }
}

// This is just a helper in case we want to enumerate it somewhere.
export const REPORT_PARAMETERS = Object.freeze(new ExagoParameters());

export class ClosedNoPolicyIssuedParameterDto {
    constructor(options) {
        options = options || {};
        this.startDate = options.startDate || null;
        this.endDate = options.endDate || null;
        this.regions = options.regions || [];
        this.branches = options.branches || [];
    }
}

export class OrdersIDParameterDto {
    constructor(options) {
        this.ordersID = _.getNumber(options, "ordersID", 0);
    }
}

export class UnderwriterParameterDto {
    constructor(options) {
        options = options || {};
        this.startDate = options.startDate || null;
        this.endDate = options.endDate || null;
        this.regions = options.regions || [];
        this.branches = options.branches || [];
        this.titleCompanies = options.titleCompanies || [];
        this.underwriterCompanies = options.underwriterCompanies || [];
    }
}

export function getQuickDates(quickDateId) {
    let startDate = DateTime.now().startOf('day');
    let endDate = DateTime.now().startOf('day');

    // let test = SPEED_DATE.getObject({ id: speedDateId });
    // let test2 = SPEED_DATE.getObjects({ id: speedDateId });
    // let test3 = SPEED_DATE.getObjectByKey(speedDateId);
    // let test4 = SPEED_DATE.getFieldByKey(speedDateId, 'period');
    // let test5 = SPEED_DATE.getFieldByFilter({ period: 'quarter' }, 'name');

    let foundDefinition = QUICK_DATE.getObjectByKey(quickDateId);

    if (!foundDefinition) {
        startDate = null; endDate = null;
    }
    // One-off
    else if (quickDateId == QUICK_DATE.YEAR_TO_DATE) {
        startDate = startDate.plus(foundDefinition.offset).startOf(foundDefinition.period);
    }
    else {
        startDate = startDate.plus(foundDefinition.offset).startOf(foundDefinition.period);
        endDate = startDate.endOf(foundDefinition.period);
    }

    return { startDate: startDate.toFormat("yyyy-MM-dd'T00:00:00'"), endDate: endDate.toFormat("yyyy-MM-dd'T00:00:00'")}
}

export function getSpeedDates(speedDateId) {
    let startDate = DateTime.now().startOf('day');
    let endDate = DateTime.now().startOf('day');

    // let test = SPEED_DATE.getObject({ id: speedDateId });
    // let test2 = SPEED_DATE.getObjects({ id: speedDateId });
    // let test3 = SPEED_DATE.getObjectByKey(speedDateId);
    // let test4 = SPEED_DATE.getFieldByKey(speedDateId, 'period');
    // let test5 = SPEED_DATE.getFieldByFilter({ period: 'quarter' }, 'name');

    let foundDefinition = SPEED_DATE.getObjectByKey(speedDateId);

    if (!foundDefinition) {
        startDate = null; endDate = null;
    }
    // One-off
    else if (speedDateId == SPEED_DATE.NEXT_7_DAYS) {
        endDate = startDate.plus(foundDefinition.offset).endOf(foundDefinition.period);
    }
    else if (speedDateId == SPEED_DATE.MONTH_TO_YESTERDAY) {
        if (startDate.day != 1){
            endDate = startDate.plus({ days:    -1 }).startOf('day');
            startDate = startDate.plus(foundDefinition.offset).startOf(foundDefinition.period);
        }
    }
    else {
        startDate = startDate.plus(foundDefinition.offset).startOf(foundDefinition.period);
        endDate = startDate.endOf(foundDefinition.period);
    }

    return { startDate: startDate.toFormat("yyyy-MM-dd'T00:00:00'"), endDate: endDate.toFormat("yyyy-MM-dd'T00:00:00'")}
}

export function generateParameterData(parameterData, parameterDefinitions) {
    return _.map(parameterDefinitions, (x) => {
        let value = _.get(parameterData, x.parameterProperty, '');
        if(Array.isArray(value)) value = _.join(value, ',');
        let parameter = {
            name: x.name,
            //dataType: x.dataType,
            value: value
        }
        return parameter;
    });
}
// export function generateParameterData2(parameterValues) {
//     return _.map(parameterValues, (val, key) => {
//         let value = val;
//         if(Array.isArray(value)) value = _.join(value, ',');
//         let parameter = {
//             name: key,
//             //dataType: x.dataType,
//             value: value
//         }
//         return parameter;
//     });
// }