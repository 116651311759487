<template>
    <div class="content-wrapper h-100">
        <report-designer-launcher
            :key="reportDesignerKey"
            @report-designer-refresh="onReportDesignerRefresh"
        />
    </div>
</template>
<script>
    import { EXAGO_SESSION_MUTATIONS } from "@/store/mutations";
    import { EXAGO_SESSION_ACTIONS } from "@/store/actions";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import ReportDesignerLauncher from "@/shared/components/exago/ReportDesignerLauncher.vue";

    export default {
        name: "ReportDesigner",
        components: { ReportDesignerLauncher },
        data() {
            return {
                reportDesignerKey: 0,
                reportPathValue: null
            }
        },
        setup() {
            const vuexStore = useStore();
            const reportAccess = computed({
                get() { return vuexStore.state.exagoSession.reportAccess; },
                set(val) { vuexStore.commit(EXAGO_SESSION_MUTATIONS.SET_REPORT_ACCESS, val); }
            });
            return {
                reportAccess
            };
        },
        created() {
            const self = this;
            self.fetchReportsAccess();
        },
        methods: {
            refreshReportDesignerFrame() {
                const self = this;
                self.reportDesignerKey += 1;
            },
            fetchReportsAccess() {
                const self = this;
                self.$store.dispatch(EXAGO_SESSION_ACTIONS.GET_REPORT_API_URL);
            },
            onReportDesignerRefresh() {
                this.refreshReportDesignerFrame();
            },
        }
    };
</script>