import { TreeViewItemModel } from "@/shared/models/rq-ui";
export class I1099FilingRequestDto {
	constructor(options) {
        options = options || {};
        this.testing = _.parseBool(options.testing, false);
        this.correction = _.parseBool(options.correction, false);
        this.lastFiling = _.parseBool(options.lastFiling, false);
        this.filingTitleCompanyCompanyID = _.parseNumber(options.filingTitleCompanyCompanyID, null);
        this.titleCompanyIDs = options.titleCompanyIDs || [];
        this.correctedFileOrdersID = options.correctedFileOrdersID || [];
        this.contactName = options.contactName || null;
        this.contactEmail = options.contactEmail || null;
        this.contactPhone = options.contactPhone || null;
        this.contactPhoneExt = options.contactPhoneExt || null;
        this.startDate = options.startDate || null;
        this.endDate = options.endDate || null;
    }
}

export class I1099FilingResultDto {
	constructor(options) {
        options = options || {};
        this.fileName = options.fileName || null;
        this.fileContent = options.fileContent || null;
        this.totalCount = _.parseNumber(options.totalCount, 0);
        this.missingAddressCount = _.parseNumber(options.missingAddressCount, 0);
        this.missingNameCount = _.parseNumber(options.missingNameCount, 0);
        this.missingIdentityCount = _.parseNumber(options.missingIdentityCount, 0);
        this.missingControlCount = _.parseNumber(options.missingControlCount, 0);
    }
}

export const filing1099ActionEnum = {
        GenerateReport: 1,
        CreateIRSFile: 2,
        SendIRSFile: 3
}

export class DocumentReportNodeDto extends TreeViewItemModel {
    constructor(options) {
        super(options, true);
        options = options || {};
        this.documentReportNodeID = _.parseNumber(options.documentReportNodeID, 0);
        this.id = this.documentReportNodeID;
        this.reportID = options.reportID || null;
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.isFolder = _.isNil(this.documentTemplateID);
        this.name = options.name || `[${this.isFolder ? "Report Folder" : "Report"} ${this.id}]`;
        this.key = `doc_report_${this.isFolder ? "folder_" : ""}${this.id}`;
        this.parentID = _.parseNumber(options.parentID, null);
        this.mapChildren(options.children);
    }

    mapChildren(items = []) {
        this.children = _.isEmpty(items) ? [] : _.map(items, c => new DocumentReportNodeDto(c));
        this.hasChildren = !_.isEmpty(this.children);
    }
}

export const I1099FileTypeOption = {
    I1099FilesOnly: 0,
    MissingInfoFilesOnly: 1
}